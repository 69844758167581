import { Page } from '@vendure/components/shared/server'
import { Accordion } from './Accordion'
import { Alert } from './Alert'
import { Article } from './Article'
import { ArticlesTeaser } from './ArticlesTeaser'
import { BlogArticles } from './BlogArticles'
import { CaseStudy } from './CaseStudy'
import { CaseStudyCarousel } from './CaseStudyCarousel'
import { CaseStudyGrid } from './CaseStudyGrid'
import { ClientLogoSlider } from './ClientLogoSlider'
import { ClientLogos } from './ClientLogos'
import { CommunityTeaser } from './CommunityTeaser'
import { CompareSlider } from './CompareSlider'
import { ConfAgenda } from './ConfAgenda'
import { ContactForm } from './ContactForm'
import { ContentSection } from './ContentSection'
import { ContentWithMedia } from './ContentWithMedia'
import { ContributorsList } from './ContributorsList'
import { FeatureGrid } from './FeatureGrid'
import { FullWidthImage } from './FullWidthImage'
import { Grid } from './Grid'
import { CustomHeading } from './Heading'
import { IncludeSnippet } from './IncludeSnippet'
import { IntegrationsTeaser } from './IntegrationsTeaser'
import { List } from './List'
import { LogoGrid } from './LogoGrid'
import { MainHero } from './MainHero'
import { PageHero } from './PageHero'
import { PartnerForm } from './PartnerForm'
import { PersonCard } from './PersonCard'
import { PricingTable } from './PricingTable'
import PrimaryContactTeaser from './PrimaryContactTeaser'
import { Row } from './Row'
import { SectionHeader } from './SectionHeader'
import { Snippet } from './Snippet'
import { StandaloneButton } from './StandaloneButton'
import { ContentTabs } from './Tabs'
import { Testimonial } from './Testimonial'
import { TestimonialCarousel } from './TestimonialCarousel'
import { CustomText } from './Text'
import { Video } from './Video'

export const components = {
  mainHero: MainHero,
  contentSection: ContentSection,
  sectionHeader: SectionHeader,
  tabs: ContentTabs,
  contentWithMedia: ContentWithMedia,
  pageHero: PageHero,
  featureGrid: FeatureGrid,
  list: List,
  heading: CustomHeading,
  accordion: Accordion,
  clientLogoSlider: ClientLogoSlider,
  row: Row,
  text: CustomText,
  snippet: Snippet,
  includeSnippet: IncludeSnippet,
  articlesTeaser: ArticlesTeaser,
  logoGrid: LogoGrid,
  testimonialCarousel: TestimonialCarousel,
  personCard: PersonCard,
  blogArticles: BlogArticles,
  caseStudyGrid: CaseStudyGrid,
  button: StandaloneButton,
  alert: Alert,
  testimonial: Testimonial,
  video: Video,
  grid: Grid,
  compareSlider: CompareSlider,
  page: Page,
  caseStudy: CaseStudy,
  article: Article,
  contactForm: ContactForm,
  fullWidthImage: FullWidthImage,
  communityTeaser: CommunityTeaser,
  primaryContactTeaser: PrimaryContactTeaser,
  caseStudyCarousel: CaseStudyCarousel,
  integrationsTeaser: IntegrationsTeaser,
  'pricing-table': PricingTable,
  'partner-form': PartnerForm,
  'contributors-list': ContributorsList,
  'conf-agenda': ConfAgenda,
  'client-logos': ClientLogos,
}
